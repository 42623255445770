import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CenterLogo from "../components/CenterLogo"
import Logo from "../components/Logo"
import styled from "styled-components"
import ImgPeter from "../images/Peter.png"
import ImgPolish from "../images/iStock-173625827.jpg"
import H2 from "../components/H2"
import IcoTeam from "../images/about-people.png"
import IcoLike from "../images/about-like.png"
import IcoCheck from "../images/about-checkmark.png"
import IcoShield from "../images/about-shield.png"
import Bgcontact from "../components/Bg"
import ContactFormer from "../components/ContactFormer"

const Relative = styled.div`
  position: relative;
`

const H2white = styled(H2)`
  color: #fff;
  
  @media (max-width: 768px) {
    font-size: 20px;
  }
`
const BgByj = styled.div`
  background-repeat: no-repeat;
  min-height: 60vh;
  background-size: cover;
  background-position: center;
  background-image: url("https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528306/page%202%20About%20BYJ/ADV_BYJ_a5ajwy.jpg");
  h1 {
    padding-top: 20vh;
  }
`
const Bg = styled.div`
  background-color: #f9f9f9;
  padding: 7vh 0;
  @media (max-width: 768px) {
    padding: 6vh 0;
  }
`
const BgCom = styled.div`
  width: 80%;
  margin: auto;
  background-repeat: no-repeat;

  background-size: cover;
  background-position: center;
  background-image: url(${ImgPolish});
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`
const ImgCenter = styled.img`
  display: block;
  margin: auto;
`
const IcoCenter = styled.img`
  display: block;
  margin: auto;
  width: 40%;
  @media (max-width: 768px) {
    width: 30%;
  }
  @media only screen and (max-width: 600px) {
    width: 80%;padding: 0 7px;
s
  }
`
const Circle = styled.div`
  background: #fff;
  border-color: grey;
  display: flex;
  flex-direction: column;
  width: 96%;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 20px;

  min-height: 20vh;
  @media only screen and (max-width: 600px) {
    min-height: 30vh !important;
    margin-bottom: 5px;
  }
  @media only screen and (max-width: 1440px) {
    min-height: 28vh !important;
  }

  /* Most Laptops */
  @media only screen and (max-width: 1200px) {
    min-height: 38vh;
  }
`
const H1 = styled.h1`
  text-shadow: 1px 1px 1px rgb(41, 41, 41);

`
const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="flex flex-wrap bg-white">
      <div className="item w-full h-auto text-center md:pt-40 pt-38">
        <BgByj>
          <H1 className="text-white text-6xl drop-shadow-lg shadow-black">
            About Beyond Jewelry
          </H1>
        </BgByj>
      </div>
    </div>{" "}
    <div className="flex flex-wrap  bg-white">
      <div className="container mx-auto px-6 md:px-16">
        <div className="item w-full h-auto text-center py-9 pt-8 md:py-14 2xl:py-16">
          <Relative>
            <CenterLogo>
              <Logo />
            </CenterLogo>
            <H2 className="2xl:text-5xl xl:text-5xl lg:text-4xl text-4xl xl:pt-1 pt-4 py-2 ">
              Company
            </H2>
          </Relative>

          <div className="item w-full h-auto text-center py-6 md:py-3 px-4 md:px-14">
            <p className="text-2xl pt-6 md:pt-11">
              Advance Manufactures Co., Ltd., a prominent jewelry manufacturer
              in Thailand, has created Beyond Jewelry GmbH-Germany as its
              subsidiary. The company offers bespoke jewelry and private label
              collections, and provides expert services in design, development,
              and production. Beyond Jewelry GmbH collaborates with established
              European jewelry firms seeking to expand their product offerings,
              as well as emerging brands and businesses interested in building
              their own extensive jewelry brands. Choose Beyond Jewelry GmbH as
              your trusted partner for customized jewelry solutions.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-gray-100 py-6 md:py-14">
      <div className="container mx-auto px-2">
        <div className="flex flex-wrap">
          <div className="item w-full h-auto text-center pt-16">
            <Relative>
              <CenterLogo>
                <Logo />
              </CenterLogo>
              <H2 className="2xl:text-5xl xl:text-5xl lg:text-4xl text-4xl xl:pt-1 pt-4 pb-2">
                People
              </H2>
            </Relative>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap overflow-hidden py-9 pt-6 md:pt-11 container mx-auto">
        <div className=" w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-1 md:px-1 md:w-1/2 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2 py-4 md:py-7">
          <ImgCenter
            className="object-contain  object-center"
            alt="Peter Hermes"
            src={ImgPeter}
          />
          <H2 className="md:text-3xl font-bold text-2xl  py-6 md:py-7 text-center">
            Peter Hermes
          </H2>
          <p className=" text-center md:text-2xl text-xl leading-8 py-4 md:pt7 md:pb-8 md:pl-12">
            Peter is Managing Director and partner of BYJ. He has a degree in
            Gemology (GG), which he passed at the well-known Gemological
            Institute of America (GIA) in Santa Monica, California. Peter has
            been working in the jewelry business over 20 years in several
            leading managing positions. He has extensive knowledge about the
            European jewelry market in terms of design, product development,
            pricing &amp; sales. He developed collections and products for
            luxury and fashion jewelry brands as well as reputable wholesale
            companies, retail chains and Instagram- &amp; TV-companies.
          </p>
        </div>
        <div className=" w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-1 md:px-1 md:w-1/2 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2  py-4 md:py-7">
          <ImgCenter
            className="object-contain  object-center"
            alt="Roma Kulisra"
            src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528306/page%202%20About%20BYJ/K.Roma_ycbk6p.jpg"
          />
          <H2 className="md:text-3xl font-bold text-2xl  py-6 md:py-7 text-center">
            Roma Kulisra
          </H2>
          <p className=" text-center md:text-2xl text-xl leading-8 py-4 md:pt7 md:pb-8 md:pl-12">
            Roma is partner and co-founder of BYJ and owner of the factory in
            Thailand. She holds a business degree in Advertising &amp; Marketing
            from Pace University in New York and a Gemology degree from
            Gemological Institute of America (GIA) Bangkok Campus in Thailand.
            Working with a wide variety of customers, designers and goldsmiths
            for the past 15 years has helped her gain an understanding of the
            complex process of creating jewelry from a customer’s view as well
            as the manufacturer’s view.
          </p>
        </div>
      </div>
    </div>
    <Bg>
      <div className="container mx-auto px-6 md:px-16 text-center">
        <div className="item w-full h-auto text-center py-6 pt-3 pb-16">
          <Relative>
            <CenterLogo>
              <Logo />
            </CenterLogo>
            <H2 className="2xl:text-5xl xl:text-5xl lg:text-4xl text-4xl xl:pt-2 pt-4 pb-2">
              factory
            </H2>
          </Relative>
        </div>
        <div className="flex flex-wrap -mx-2 overflow-hidden sm:-mx-2 md:-mx-1 lg:-mx-1 xl:-mx-1">
          <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/5 xl:my-1 xl:px-1 xl:w-1/5">
            <Circle>
              <p className="text-4xl font-extrabold pt-14 md:pt-14">1989</p>
              <p className="text-2xl pt-6 py-4">Established </p>
            </Circle>
          </div>
          <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/5 xl:my-1 xl:px-1 xl:w-1/5">
            <Circle>
              <p className="text-4xl font-extrabold pt-14 md:pt-14">
                3 <br />
                Mio pieces
              </p>
              <p className="text-2xl py-4">Yearly produced jewelry</p>
            </Circle>
          </div>
          <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/5 xl:my-1 xl:px-1 xl:w-1/5">
            <Circle>
              <p className="text-4xl font-extrabold pt-14 md:pt-14 ">
                600
                <br />
                models
              </p>
              <p className="text-2xl py-2 px-4">
                Monthly model making capacity
              </p>
            </Circle>
          </div>
          <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/5 xl:my-1 xl:px-1 xl:w-1/5">
            <Circle>
              <p className="text-4xl font-extrabold pt-14 md:pt-14">450</p>
              <p className="text-2xl py-4">
                Number <span className="block">of</span> employees
              </p>
            </Circle>
          </div>
          <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/5 xl:my-1 xl:px-1 xl:w-1/5">
            <Circle>
              <p className="text-4xl font-extrabold pt-14 md:pt-14">
                130{" "}
                <span className="text-3xl font-extrabold pt-14">clients</span>
                <br />
                <span className="text-3xl font-extrabold pt-14">
                  in
                  <br />
                </span>{" "}
                35{" "}
                <span className="text-3xl font-extrabold pt-14 pb-4">
                  countries
                </span>
              </p>
            </Circle>
          </div>
        </div>
      </div>
    </Bg>
    <div className="flex flex-wrap">
      <div className="container mx-auto  md:px-16 ">
        <BgCom>
          <div className="item w-full h-auto py-9 px-2 pt-16 md:py-19 md:px-24">
            <Relative>
              <CenterLogo>
                <Logo />
              </CenterLogo>
              <H2white className="2xl:text-5xl xl:text-5xl lg:text-4xl text-4xl xl:pt-2 text-center pt-4 pb-2">
                Commitment
              </H2white>
            </Relative>
            <div className="flex flex-row flex-wrap overflow-hidden px-8 md:-mx-3 lg:-mx-3 xl:-mx-3 py-4 md:pt-4 text-white xl:pt-20 pt-12">
              <div className="basis-1/5 pt-6 md:pt-20">
                <IcoCenter
                  className="object-center"
                  alt="a changer"
                  src={IcoTeam}
                />
              </div>{" "}
              <div className="basis-4/5">
                {" "}
                <h3 className="md:text-5xl text-4xl  py-6 md:py-3 md:pt-1 ">
                  Responsibility
                </h3>
                <p className="text-lg ">
                  We are committed to transparent and responsible sourcing
                  practices and the use of sustainable materials. It is
                  important to us that our production has a minimal impact on
                  the environment, the raw materials that we source are not
                  involved with conflict, and that we give back to workers by
                  way of fair wages and safe working environments. Environmental
                  awareness is a natural part of who we are and everything we do
                  – from the raw materials and production process all the way to
                  the finished product and packaging. We are dedicated to
                  reducing our carbon footprint and work to find new ways to
                  contribute in nurturing the environment.
                </p>
              </div>
            </div>
            <div className="flex flex-row flex-wrap overflow-hidden px-8 md:-mx-3 lg:-mx-3 xl:-mx-3 py-4 md:pt-4 text-white">
              <div className="basis-1/5 pt-6 md:pt-20">
                <IcoCenter
                  className="object-center"
                  alt="a changer"
                  src={IcoCheck}
                />
              </div>{" "}
              <div className="basis-4/5">
                {" "}
                <h3 className="md:text-5xl text-4xl  py-6 md:py-3 md:pt-12 ">
                  Values
                </h3>
                <p className="text-lg ">
                  Our core values are deeply rooted in family values which
                  cultivates an environment of trust, honesty, respect,
                  empowerment and teamwork in the workplace. We don’t measure
                  success only by the quality of our products and services and
                  products, but rather on the relationship with our customers
                  and suppliers. Our cooperation with business partners,
                  suppliers and employees has always and will always be based on
                  honesty and trust.
                </p>
              </div>
            </div>
            <div className="flex flex-row flex-wrap overflow-hidden px-8 md:-mx-3 lg:-mx-3 xl:-mx-3 py-4 md:pt-4 text-white">
              <div className="basis-1/5 pt-6 md:pt-20">
                <IcoCenter
                  className="object-center"
                  alt="a changer"
                  src={IcoShield}
                />
              </div>{" "}
              <div className="basis-4/5">
                {" "}
                <h3 className="md:text-5xl text-4xl  py-6 md:py-3 md:pt-12 ">
                  Reliability &amp; Quality{" "}
                </h3>
                <p className="text-lg ">
                  Reliability and quality are part of the culture of our
                  organization. We have been consistently delivering quality
                  craftsmanship around the world for over 30 years. To ensure we
                  maintain our high standards, we constantly work on improving
                  our manufacturing and administration processes.
                </p>
              </div>
            </div>
            <div className="flex flex-row flex-wrap overflow-hidden px-8 md:-mx-3 lg:-mx-3 xl:-mx-3 py-4 md:pt-4 text-white">
              <div className="basis-1/5 pt-6 md:pt-20">
                <IcoCenter
                  className="object-center"
                  alt="a changer"
                  src={IcoLike}
                />
              </div>{" "}
              <div className="basis-4/5">
                {" "}
                <h3 className="md:text-5xl text-4xl  py-6 md:py-3 md:pt-12">
                  Promise
                </h3>
                <p className="text-lg ">
                  We succeed when our clients succeed. You can fully rely on us
                  as your partner who ensures your success in the jewelry
                  market. We keep our promises and share our opinions openly. We
                  are loyal to our business partners and keep their intellectual
                  property confidential. Our motto is “We love what we do..”
                </p>
              </div>
            </div>
          </div>{" "}
        </BgCom>
      </div>
    </div>
    <Bgcontact>
      <ContactFormer />
    </Bgcontact>
  </Layout>
)

export default IndexPage
